import Swiper from 'swiper';
import { Navigation, FreeMode } from 'swiper/modules';
import 'swiper/modules/free-mode.scss';
import 'swiper/swiper.scss';

document.addEventListener('DOMContentLoaded', () => {
    
    const carousel = document.querySelectorAll('.js-carousel');
   
    const carousel_app = () => {
        for (let i = 0; i < carousel.length; i ++) {
            const swiper = new Swiper(carousel[i], {
                modules: [Navigation, FreeMode],
                breakpoints: {
                    561: {
                        freeMode: {
                            enabled: true,
                            sticky: false,
                        },
                    },
                },
                freeMode: false,
                on: {
                    init: (e) => {
                        if (carousel[i].parentNode.parentNode.classList.contains('negative')) {
                            e.slideTo(2, 0);
                        }
                    },
                },
                navigation: {
                    nextEl: carousel[i].parentNode.querySelector('.swiper-button-next'),
                    prevEl: carousel[i].parentNode.querySelector('.swiper-button-prev')
                },
                slidesPerView: 'auto',
                speed: 600,
                spaceBetween: 16
            });
        }
    };
   
    window.carousels = () => {
        carousel.length > 0 ? carousel_app() : false;
    }
}, false)
