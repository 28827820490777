document.addEventListener('DOMContentLoaded', () => {
    
    const el = document.querySelector('.js-topbar');
    const action = () => {
        let scroll_pos = window.pageYOffset || window.scrollY;
        scroll_pos > 0 ? el.classList.add('is-narrow') : el.classList.remove('is-narrow');
    }

    el ? window.addEventListener('scroll', action) : false;

}, false);
