document.addEventListener('DOMContentLoaded', () => {

    const cover = document.getElementById('cover');
    
    const init = () => {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        window.anims();
        window.carousels();
        
        setTimeout(() => {
            cover.remove();
        }, 250);  
    };
    
    window.addEventListener('load', init);

}, false);