import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

document.addEventListener('DOMContentLoaded', () => {
    
    window.runScroll = function(el, o) {
        let offsetTop = document.querySelector(el).offsetTop;
        let topbar_height = document.querySelector('.js-topbar').clientHeight;

        o === undefined ? o = 0 : false;

        scroll({
            top: offsetTop-topbar_height - o,
            behavior: "smooth"
        })
    };

    const gtt = document.querySelectorAll("[data-target]");

    if (gtt.length > 0) {
        const action = (e) => {
        	e.preventDefault() ? e.preventDefault() : e.preventDefault = false;  
            let target = e.currentTarget.dataset.target,
                offset = e.currentTarget.dataset.offset;
            window.runScroll(target, offset);
        };

        for (let i = 0; i < gtt.length; i++) {
            gtt[i].addEventListener('click', action);
        }
    }
    
}, false);
