import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {
        if (document.querySelectorAll('.gs-fade').length > 0) {
            gsap.utils.toArray(".gs-fade > *").forEach((section) => {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete complete",
                    },
                    autoAlpha: 0, 
                    duration: 1,
                    y: '50px'
                });
            });
        }
      
        if (document.querySelectorAll('.gs-carousel').length > 0) {
            gsap.utils.toArray(".gs-carousel").forEach((section) => {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        toggleActions: "play complete complete complete",
                        scrub: 2.2,
                        start: 'top bottom',
                        end: 'top center'                        
                    },
                    xPercent: 15,
                });
            });
        }
    };
    
}, false)
