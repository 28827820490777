import { gsap, Power1 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {

    const el = document.querySelectorAll('.js-accordion');
    
    window.accordion = (obj) => {
        let item = obj.querySelectorAll('.js-item'),
            tl;
  
        const toggle = function(ev, section, op) {
            tl = gsap.timeline({clearProps:"all"});

            tl.to(section, {
                height: ev,
                duration: .25,
                ease: Power1.easeOut,
            })
            .fromTo(section, .5, {
                opacity: 0,
                y: 20,
            }, {
                opacity: 1,
                y: 0,
            })
        }

        const action = (e) => {
            const _that = e.currentTarget,
                  _height = 0;
                        
            if (_that.classList.contains('is-rollout')) {
                _that.classList.remove('is-rollout');
                toggle(_height, _that.getElementsByClassName('js-section')[0], 0);  
                
            } else {
                for (let i = 0; i < item.length; i ++) {
                    item[i].classList.remove('is-rollout');
                    toggle(_height, item[i].getElementsByClassName('js-section')[0], 0);
                }   
    
                _that.classList.add('is-rollout');
                toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
                
                if (_that.dataset.id) {
                    setTimeout(() => {
                        runScroll('[data-id="'+_that.dataset.id+'"]', 80);
                    }, 250);
                }
            }
        };

        const start = () => {  
            for( let i = 0; i < item.length; i ++ ) {    
                item[i].addEventListener('click', action);
                if (item[i].classList.contains('is-rollout')) {
                    toggle('auto', item[i].getElementsByClassName('js-section')[0], 1);
                }
            }        
        }
        start() 
    };

    if (el.length > 0) {
        for (let i = 0; i < el.length; i++) {
            accordion(el[i]);
        }
    }

}, false);
